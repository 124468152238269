// Used to populate consistent list of shipping carriers
export const carriers = [
  {value: 'FedEx', label: 'FedEx'},
  {value: 'Old Dominion Freight', label: 'Old Dominion Freight'},
  {value: 'UPS', label: 'UPS'},
  {value: 'Veolia', label: 'Veolia'},
  {value: 'XPO Logistics', label: 'XPO Logistics'},
  {value: 'YRC Freight', label: 'YRC Freight'},
];

// Used to populate consistent list of shipment types
export const shipmentTypes = [
  {value: 'Inbound Order', label: 'Inbound Order'},
  {value: 'Outbound Order', label: 'Outbound Order'},
  {value: 'Third Party', label: 'Third Party'},
];

// Used to populate consistent list of inventory stock statuses on inbound
export const inboundStatuses = [
  {value: 'OK', label: 'OK'}, // Inventory should only be shipped outbound when status is "OK"
  {value: 'Hold', label: 'Hold'},
  {value: 'Damaged', label: 'Damaged'},
  {value: 'Inspection Required', label: 'Inspection Required'},
];

// A complete list of all possible inventory stock statuses
export const allStatuses = [
  ...inboundStatuses,
  {value: 'Pending Pick', label: 'Pending Pick'},
  {value: 'Picked', label: 'Picked'},
  {value: 'Outbound', label: 'Outbound'},
];

// Common Regex variables
export const postalRegex = /^((\d{5}-\d{4})|(\d{5})|([A-Z]\d[A-Z]\s\d[A-Z]\d))$/im;
export const mexicoPostalRegex = /[0-9]{5}/g;
export const koreaPostalRegex = /[0-9]{3}-[0-9]{3}/g;
export const japanPostalCodeRegex = /[0-9]{3}-[0-9]{4}/g;
export const phoneRegex = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im; // eslint-disable-line no-useless-escape

// States and provinces for United States, Canada and Mexico
export const usStates = [
  {value: 'AK'},
  {value: 'AL'},
  {value: 'AR'},
  {value: 'AS'},
  {value: 'AZ'},
  {value: 'CA'},
  {value: 'CO'},
  {value: 'CT'},
  {value: 'DC'},
  {value: 'DE'},
  {value: 'FL'},
  {value: 'GA'},
  {value: 'GU'},
  {value: 'HI'},
  {value: 'IA'},
  {value: 'ID'},
  {value: 'IL'},
  {value: 'IN'},
  {value: 'KS'},
  {value: 'KY'},
  {value: 'LA'},
  {value: 'MA'},
  {value: 'MD'},
  {value: 'ME'},
  {value: 'MI'},
  {value: 'MN'},
  {value: 'MO'},
  {value: 'MS'},
  {value: 'MT'},
  {value: 'NC'},
  {value: 'ND'},
  {value: 'NE'},
  {value: 'NH'},
  {value: 'NJ'},
  {value: 'NM'},
  {value: 'NV'},
  {value: 'NY'},
  {value: 'OH'},
  {value: 'OK'},
  {value: 'OR'},
  {value: 'PA'},
  {value: 'PR'},
  {value: 'RI'},
  {value: 'SC'},
  {value: 'SD'},
  {value: 'TN'},
  {value: 'TX'},
  {value: 'UT'},
  {value: 'VA'},
  {value: 'VI'},
  {value: 'VT'},
  {value: 'WA'},
  {value: 'WI'},
  {value: 'WV'},
  {value: 'WY'},
];
export const canadaProvinces = [
  {value: 'AB'},
  {value: 'BC'},
  {value: 'MB'},
  {value: 'NB'},
  {value: 'NL'},
  {value: 'NT'},
  {value: 'NS'},
  {value: 'NU'},
  {value: 'ON'},
  {value: 'PE'},
  {value: 'QC'},
  {value: 'SK'},
  {value: 'YT'},
];
export const mexicanStates = [
  {value: 'AG'},
  {value: 'BN'},
  {value: 'BS'},
  {value: 'CH'},
  {value: 'CI'},
  {value: 'CL'},
  {value: 'CP'},
  {value: 'CS'},
  {value: 'DF'},
  {value: 'DG'},
  {value: 'GE'},
  {value: 'GJ'},
  {value: 'HD'},
  {value: 'JA'},
  {value: 'MC'},
  {value: 'MR'},
  {value: 'MX'},
  {value: 'NA'},
  {value: 'NL'},
  {value: 'OA'},
  {value: 'PU'},
  {value: 'QE'},
  {value: 'QI'},
  {value: 'SI'},
  {value: 'SL'},
  {value: 'SO'},
  {value: 'TA'},
  {value: 'TB'},
  {value: 'TL'},
  {value: 'VC'},
  {value: 'YU'},
  {value: 'ZA'},
];
export const japanesePrefectures = [
  {value: 'Hokkaido'},
  {value: 'Aomori Ken'},
  {value: 'Iwate Ken'},
  {value: 'Miyagi Ken'},
  {value: 'Akita Ken'},
  {value: 'Yamagata Ken'},
  {value: 'Fukushima Ken'},
  {value: 'Ibaraki Ken'},
  {value: 'Tochigi Ken'},
  {value: 'Gunma Ken'},
  {value: 'Saitama Ken'},
  {value: 'Chiba Ken'},
  {value: 'Tokyo To'},
  {value: 'Kanagawa Ken'},
  {value: 'Niigata Ken'},
  {value: 'Toyama Ken'},
  {value: 'Ishikawa Ken'},
  {value: 'Fukui Ken'},
  {value: 'Yamanashi Ken'},
  {value: 'Nagano Ken'},
  {value: 'Gifu Ken'},
  {value: 'Shizuoka Ken'},
  {value: 'Aichi Ken'},
  {value: 'Mie Ken'},
  {value: 'Shiga Ken'},
  {value: 'Kyoto Fu'},
  {value: 'Osaka Fu'},
  {value: 'Hyōgo Ken'},
  {value: 'Nara Ken'},
  {value: 'Wakayama Ken'},
  {value: 'Tottori Ken'},
  {value: 'Shimane Ken'},
  {value: 'Okayama Ken'},
  {value: 'Hiroshima Ken'},
  {value: 'Yamaguchi Ken'},
  {value: 'Tokushima Ken'},
  {value: 'Kagawa Ken'},
  {value: 'Ehime Ken'},
  {value: 'Kōchi Ken'},
  {value: 'Fukuoka Ken'},
  {value: 'Saga Ken'},
  {value: 'Nagasaki Ken'},
  {value: 'Kumamoto Ken'},
  {value: 'Ōita Ken'},
  {value: 'Miyazaki Ken'},
  {value: 'Kagoshima Ken'},
  {value: 'Okinawa Ken'},
];

export const southKoreanDistricts = [
  {value: 'Sangnok-gu'},
  {value: 'Danwon-gu'},
  {value: 'Dongan-gu'},
  {value: 'Manan-gu'},
  {value: 'Buk District'},
  {value: 'Busanjin District'},
  {value: 'Dong District'},
  {value: 'Dongnae District'},
  {value: 'Gangseo Districtvalue'},
  {value: 'Geumjeong District'},
  {value: 'Haeundae District'},
  {value: 'Jung District'},
  {value: 'Nam District'},
  {value: 'Saha District'},
  {value: 'Sasang District'},
  {value: 'Seo District'},
  {value: 'Suyeong District'},
  {value: 'Yeongdo District'},
  {value: 'Yeonje District'},
  {value: 'Jinhae-gu'},
  {value: 'Masanhappo-gu'},
  {value: 'Masanhoewon-gu'},
  {value: 'Seongsan-gu'},
  {value: 'Uichang-gu'},
  {value: 'Heungdeok-gu'},
  {value: 'Sangdang-gu'},
  {value: 'Cheongwon-gu'},
  {value: 'Seowon-gu'},
  {value: 'Dongnam-gu'},
  {value: 'Seobuk-gu'},
  {value: 'Jung District'},
  {value: 'Dong District'},
  {value: 'Seo District'},
  {value: 'Nam District'},
  {value: 'Buk District'},
  {value: 'Suseong District'},
  {value: 'Dalseo District'},
  {value: 'Daedeok District'},
  {value: 'Dong District'},
  {value: 'Jung District'},
  {value: 'Seo District'},
  {value: 'Yuseong District'},
  {value: 'Deogyang-gu'},
  {value: 'Ilsandong-gu'},
  {value: 'Ilsanseo-gu'},
  {value: 'Buk District'},
  {value: 'Dong District'},
  {value: 'Gwangsan District'},
  {value: 'Nam District'},
  {value: 'Seo District'},
  {value: 'Bupyeong District'},
  {value: 'Dong District'},
  {value: 'Gyeyang District'},
  {value: 'Jung District'},
  {value: 'Namdong District'},
  {value: 'Michuhol District'},
  {value: 'Seo District'},
  {value: 'Yeonsu District'},
  {value: 'Deokjin-gu'},
  {value: 'Wansan-gu'},
  {value: 'Buk-gu'},
  {value: 'Nam-gu'},
  {value: 'Bundang-gu'},
  {value: 'Jungwon-gu'},
  {value: 'Sujeong-gu'},
  {value: 'Dobong District'},
  {value: 'Dongdaemun District'},
  {value: 'Dongjak District'},
  {value: 'Eunpyeong District'},
  {value: 'Gangbuk District'},
  {value: 'Gangdong District'},
  {value: 'Gangnam District'},
  {value: 'Gangseo District'},
  {value: 'Geumcheon District'},
  {value: 'Guro District'},
  {value: 'Gwanak District'},
  {value: 'Gwangjin District'},
  {value: 'Jongno District'},
  {value: 'Jung District'},
  {value: 'Jungnang District'},
  {value: 'Mapo District'},
  {value: 'Nowon District'},
  {value: 'Seocho District'},
  {value: 'Seodaemun District'},
  {value: 'Seongbuk District'},
  {value: 'Seongdong District'},
  {value: 'Songpa District'},
  {value: 'Yangcheon District'},
  {value: 'Yeongdeungpo District'},
  {value: 'Yongsan District'},
  {value: 'Gwonseon-gu'},
  {value: 'Jangan-gu'},
  {value: 'Paldal-gu'},
  {value: 'Yeongtong-gu'},
  {value: 'Buk District'},
  {value: 'Dong District'},
  {value: 'Jung District'},
  {value: 'Nam District'},
  {value: 'Cheoin-gu'},
  {value: 'Giheung-gu'},
  {value: 'Suji-gu'},
];

export const shippingLocationTypes = ['Dealership', 'Affiliate', 'PDC', 'Other'];

export const shippingCountryOptions = ['United States', 'Canada', 'Mexico', 'South Korea', 'Japan'];
