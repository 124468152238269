import React, {useMemo} from 'react';
import {useHistory} from 'react-router-dom';
import {Button, Paper, Typography} from '@mui/material';
import {ErrorOutline} from '@mui/icons-material';

const ButtonDashboard = () => {
  const {replace: historyReplace} = useHistory();
  const buttonProps = useMemo(() => ({
    color: 'primary',
    style: {margin: '8px'},
    variant: 'outlined',
    onClick: () => historyReplace('/portal'),
  }), [historyReplace]);

  return <Button {...buttonProps}>Navigate Back to Portal Dashboard</Button>;
};
const ButtonContact = () => {
  const buttonProps = useMemo(() => ({
    color: 'primary',
    style: {margin: '8px'},
    variant: 'outlined',
    onClick: () => {
      window.location.href = 'mailto:daniel@kbirecycling.com?Subject=Persisting%20xEV-Strategies%20Error&Body=Describe%20Error%20Below:%0D%0A';
    },
  }), []);

  return <Button {...buttonProps}>Contact us if the problem persists</Button>;
};

const ErrorBoundary = () => {
  return (
    <Paper elevation={0} style={styles.paper}>
      <ErrorOutline style={styles.errorIcon} />
      <Typography gutterBottom={true} style={styles.textOops} variant='h6'>Oops, something went wrong. Sorry!</Typography>
      <Typography gutterBottom={true} style={styles.textNotified} variant='subtitle1'>Our team has been notified and will be looking into the issue as soon as possible.</Typography>
      <ButtonDashboard />
      <div>or</div>
      <ButtonContact />
    </Paper>
  );
};

const styles = {
  paper: {
    alignItems: 'center',
    border: '1px solid lightgray',
    display: 'flex',
    flexDirection: 'column',
    margin: '24px',
    padding: `16px 24px 24px`,
  },
  errorIcon: {fontSize: '10em'},
  textOops: {margin: '8px'},
  textNotified: {margin: '8px'},
};
export default ErrorBoundary;
