/* eslint-disable react-hooks/rules-of-hooks */
import firebase from 'firebase/compat/app';
import 'firebase/compat/storage';
import {getAuth, connectAuthEmulator, onAuthStateChanged, onIdTokenChanged} from 'firebase/auth';
import {signInWithCustomToken, signOut, updateEmail, updateProfile} from 'firebase/auth';
import {getFirestore, addDoc, arrayRemove, arrayUnion, collection, connectFirestoreEmulator, deleteDoc, doc, getDoc, getDocs} from 'firebase/firestore';
import {limit, onSnapshot, orderBy, query, runTransaction, serverTimestamp, setDoc, updateDoc, where, writeBatch} from 'firebase/firestore';
import {getFunctions, connectFunctionsEmulator, httpsCallable} from 'firebase/functions';

const Fire = firebase.initializeApp({
  apiKey: 'AIzaSyDvz3MVMz-MGhMGTVuUC2k8ocgjT_AOYEM',
  authDomain: 'xev-strategies.firebaseapp.com',
  projectId: 'xev-strategies',
  storageBucket: 'xev-strategies.appspot.com',
  messagingSenderId: '906752002880',
  appId: '1:906752002880:web:a2ccc29b75ed2b7803c8a5',
  measurementId: 'G-NMCPRCWRGX',
});

// Check to see if client is currently in development mode
const inDevelopmentMode = window.location.hostname === 'localhost';

// Initialize Firebase Authentication with conditional emulator override for development mode
const AuthInit = getAuth();
// Add conditional emulator override for development mode
if (inDevelopmentMode) connectAuthEmulator(AuthInit, 'http://localhost:9099');
const Auth = {
  currentUser: () => AuthInit.currentUser,
  onAuthStateChanged: cb => onAuthStateChanged(AuthInit, cb),
  onIdTokenChanged: cb => onIdTokenChanged(AuthInit, cb),
  signInWithCustomToken: customToken => signInWithCustomToken(AuthInit, customToken),
  signOut: () => signOut(AuthInit),
  updateEmail: email => updateEmail(AuthInit.currentUser, email),
  updateProfile: profile => updateProfile(AuthInit.currentUser, profile),
};

// Initialize Firebase Firestore with conditional emulator override for development mode
const FirestoreInit = getFirestore();
if (inDevelopmentMode) connectFirestoreEmulator(FirestoreInit, 'localhost', 8080);
const Firestore = {
  addDoc,
  arrayRemove,
  arrayUnion,
  collection: collectionPath => collection(FirestoreInit, collectionPath),
  deleteDoc,
  doc: param => {
    // When param type is a Firestore collection, passing FirestoreInit will cause system crash (Init already passed to collection);
    if (param?.type === 'collection') return doc(param);
    // Otherewise pass FirestoreInit to properly initialize doc module;
    return doc(FirestoreInit, param);
  },
  getDoc,
  getDocs,
  limit,
  onSnapshot,
  orderBy,
  query,
  runTransaction: transaction => runTransaction(FirestoreInit, transaction),
  setDoc,
  serverTimestamp,
  updateDoc,
  where,
  writeBatch: () => writeBatch(FirestoreInit),
};

// Initialize Firebase Functions with conditional emulator override for development mode
const FunctionsInit = getFunctions();
if (inDevelopmentMode) connectFunctionsEmulator(FunctionsInit, 'localhost', 5001);
const Functions = {
  httpsCallable: functionName => httpsCallable(FunctionsInit, functionName),
};

// Initialize Firebase Storage
const Storage = Fire.storage();
if (inDevelopmentMode) Storage.useEmulator('localhost', 9199);

export {Auth, Firestore, Functions, Storage};
