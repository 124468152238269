import React from 'react';
import {Dashboard as DashboardIcon, PieChart, Settings} from '@mui/icons-material';
import {Inventory as InventoryIcon, WebPortal} from 'icons';

// Lazy load routes to reduce main Webpack bundle size; Wrapped with React Suspense in MainView component;
const Dashboard = React.lazy(() => import('./views/Dashboard'));
const Inventory = React.lazy(() => import('./views/Inventory'));
const ClientPortals = React.lazy(() => import('./views/ClientPortals'));
const Reports = React.lazy(() => import('./views/Reports'));
const Administration = React.lazy(() => import('./views/Administration'));

export const routes = [
  {component: () => <Dashboard />, exact: true, strict: true, path: '/portal/dashboard', pageTitle: 'Dashboard'},
  {component: () => <Inventory />, exact: false, strict: true, path: '/portal/inventory', pageTitle: 'Inventory'},
  {component: () => <ClientPortals />, exact: false, strict: true, path: '/portal/client-portals', pageTitle: 'Client Portals'},
  {component: () => <Reports />, exact: false, strict: true, path: '/portal/reports', pageTitle: 'Reports'},
  {component: () => <Administration />, exact: false, strict: true, path: '/portal/administration', pageTitle: 'Administration'},
];

export const menuItems = (currentUser) => {
  const items = [];

  const dashboardItem = {icon: () => <DashboardIcon />, text: 'Dashboard', link: '/portal/dashboard'};
  if (['Admin', 'Manager', 'User'].includes(currentUser?.roleXevStrategies)) items.push(dashboardItem);

  const inventoryItem = {icon: () => <InventoryIcon />, text: 'Inventory', link: '/portal/inventory'};
  if (['Admin', 'Manager', 'User'].includes(currentUser?.roleXevStrategies)) items.push(inventoryItem);

  const clientPortalsItem = {icon: () => <WebPortal />, text: 'Client Portals', link: '/portal/client-portals'};
  if (['Admin'].includes(currentUser?.roleXevStrategies)) items.push(clientPortalsItem);

  const reportsItem = {icon: () => <PieChart />, text: 'Reports', link: '/portal/reports'};
  if (['Admin', 'Manager', 'User'].includes(currentUser?.roleXevStrategies)) items.push(reportsItem);

  const administrationItem = {icon: () => <Settings />, text: 'Administration', link: '/portal/administration'};
  if (['Admin', 'Manager'].includes(currentUser?.roleXevStrategies)) items.push(administrationItem);

  return items;
};
