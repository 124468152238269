import React, {useCallback} from 'react';
import {ListItemButton, ListItemIcon, ListItemText} from '@mui/material';
import {PowerSettingsNew} from '@mui/icons-material';
import {Auth} from 'firebaseConfig';

const LogoutItem = () => {
  const handleLogout = useCallback(() => {
    Auth.signOut();
    const redirect = !process.env.NODE_ENV || process.env.NODE_ENV === 'development' ? 'http://localhost:3000/signin' : 'https://xev.kbi.works/signin';
    setTimeout(() => window.location = redirect, 250);
  }, []);

  return (
    <ListItemButton sx={styles.listItemButton} onClick={handleLogout}>
      <ListItemIcon sx={styles.listItemIcon}>
        <PowerSettingsNew />
      </ListItemIcon>
      <ListItemText primary={'Log Out'} sx={styles.listItemTextPrimary} />
    </ListItemButton>
  );
};

const styles = {
  listItemButton: {
    'margin': '4px 0px',
    ':hover': {
      background: `rgba(237, 28, 36, 0.25)`,
      borderRadius: '4px',
    },
    '& .MuiListItemIcon-root': {color: 'inherit'},
  },
  listItemIcon: {
    '& .MuiSvgIcon-root': {color: 'inherit'},
  },
  listItemTextPrimary: {
    '& .MuiTypography-root': {color: 'text.primary', fontSize: '16px'},
  },
};
export default React.memo(LogoutItem);
