/* eslint-disable max-len */
import React from 'react';
import {SvgIcon} from '@mui/material';

const ShipOutbound = (props) => (
  <SvgIcon viewBox="0 0 512 512" {...props}>
    <path d="m0 408.167h197.888v21.517c0 17.598-14.265 31.865-31.862 31.867h-59.801v30h299.552v-30h-59.801c-17.598-.003-31.862-14.269-31.862-31.867v-21.517h197.886v-48.734h-512z" />
    <path d="m247.514 229.879c3.033 11.438 6.33 17.771 8.516 20.817 4.884-6.85 12.505-27.535 14.334-60.756h-28.715c.819 14.682 2.831 28.498 5.865 39.939z" />
    <path d="m264.486 120.002c-3.033-11.438-6.33-17.771-8.516-20.817-4.884 6.85-12.505 27.535-14.334 60.756h28.715c-.819-14.682-2.831-28.498-5.865-39.939z" />
    <path d="m179.97 159.94h31.636c.998-20.046 3.995-38.692 8.681-53.746-20.541 10.714-35.709 30.351-40.317 53.746z" />
    <path d="m291.713 106.194c4.686 15.055 7.683 33.701 8.681 53.746h31.636c-4.608-23.395-19.776-43.032-40.317-53.746z" />
    <path d="m0 20.449v308.984h512v-308.984zm256 261.991c-59.275 0-107.5-48.224-107.5-107.5s48.225-107.5 107.5-107.5 107.5 48.224 107.5 107.5-48.225 107.5-107.5 107.5z" />
    <path d="m291.713 243.687c20.541-10.714 35.709-30.351 40.317-53.746h-31.636c-.998 20.045-3.994 38.691-8.681 53.746z" />
    <path d="m179.97 189.94c4.608 23.395 19.776 43.032 40.317 53.746-4.686-15.055-7.683-33.701-8.681-53.746z" />
  </SvgIcon>
);

export default ShipOutbound;
