/* eslint-disable max-len */
import React from 'react';
import {SvgIcon} from '@mui/material';

const Box = (props) => (
  <SvgIcon viewBox='0 0 64 64' {...props}>
    <path d='m32.001 58c-.346 0-.69-.089-1-.268l-20.785-12c-.619-.357-1-1.018-1-1.732v-24c0-.714.381-1.375 1-1.732l20.785-12c.619-.357 1.381-.357 2 0l20.783 12c.619.357 1 1.018 1 1.732v24c0 .714-.381 1.375-1 1.732l-20.783 12c-.31.179-.654.268-1 .268zm-18.785-15.155 18.785 10.845 18.783-10.845v-21.69l-18.783-10.845-18.785 10.845z' />
    <path d='m32.001 58c-1.104 0-2-.896-2-2v-24c0-1.104.896-2 2-2s2 .896 2 2v24c0 1.104-.896 2-2 2z' />
    <path d='m31.999 34c-.339 0-.683-.086-.998-.269l-20.785-12c-.957-.551-1.284-1.774-.733-2.731.553-.957 1.772-1.286 2.732-.732l20.785 12c.957.552 1.284 1.775.732 2.732-.37.642-1.042 1-1.733 1z' />
    <path d='m32.003 34c-.691 0-1.363-.359-1.734-1-.552-.957-.225-2.18.732-2.732l20.783-12c.956-.554 2.179-.225 2.732.732.552.957.225 2.18-.732 2.732l-20.783 12c-.314.182-.659.268-.998.268z' />
  </SvgIcon>
);

export default Box;
