/* eslint-disable max-len */
import React from 'react';
import {SvgIcon} from '@mui/material';

const ShipOutbound = (props) => (
  <SvgIcon viewBox="0 0 512 512" {...props}>
    <path d="M361,396c-11.046,0-20,8.954-20,20v56H55V40h286v56c0,11.046,8.954,20,20,20c11.046,0,20-8.954,20-20V20
      c0-11.046-8.954-20-20-20H35C23.954,0,15,8.954,15,20v472c0,11.046,8.954,20,20,20h326c11.046,0,20-8.954,20-20v-76
      C381,404.954,372.046,396,361,396z" />
    <path d="M491.141,241.858l-53.74-53.74c-7.81-7.811-20.473-7.81-28.284,0c-7.811,7.81-7.811,20.474,0,28.285L428.715,236
      l-165.678-0.001c-11.045,0-20,8.954-20,20c0,11.046,8.954,20,20,20L428.717,276l-19.599,19.599
      c-7.811,7.809-7.811,20.473,0,28.284c7.81,7.811,20.474,7.809,28.284-0.001l53.74-53.74c0.001-0.002,0.003-0.004,0.004-0.005
      c4.024-4.027,5.709-8.682,5.854-14.137C497,250.608,494.709,245.424,491.141,241.858z" />
  </SvgIcon>
);

export default ShipOutbound;
