import Hashids from 'hashids';
import {DateTime} from 'luxon';

/**
 * A hook to generate a stock number using Hashid library. https://www.npmjs.com/package/hashids
 * @param {string} customSalt Parameter should be passed to ensure generated Hashids are unique. Defaults to 'stockNoGenerator' if no customSalt
 * passed on execution. In most cases a related Firestore collection name should be used as the customSalt.
 * @param {number} customPadding Parameter should be passed to ensure a minimum length for Hashids. Defaults to 5 if no customPadding is passed.
 * @param {string} customAlphabet Parameter should be passed to ensure Hashids only uses certain alpha-numeric characters. If no customAlphabet is passed,
 * Defaults to 'ABCDEFGHJKLMNPQRSTUVWXYZ23456789' (i.e. Uppercase letters and numbers, excluding commonly mistaken characters like I, O, 0).
 * @return {function}
 */
const useStockNoGenerator = (customSalt, customPadding, customAlphabet) => {
  const salt = customSalt || 'stockNoGenerator';
  const padding = customPadding || 5;
  const alphabet = customAlphabet || 'ABCDEFGHJKLMNPQRSTUVWXYZ23456789';

  // Create hashids formatter using Hashids constructor
  const hashids = new Hashids(salt, padding, alphabet);

  // Create current Julian Date (e.g. March 22, 2021 => 21081)
  const date = DateTime.now().toFormat('yyooo');

  return (countNo) => `${date}-${hashids.encode(countNo)}`;
};

export default useStockNoGenerator;
