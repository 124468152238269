import {LinearProgress} from '@mui/material';
import {Box} from '@mui/material';
import {AppBar, Drawer, MainView} from 'components/AppFrame/';
import {ErrorBoundary, Snackbar} from 'components';
import {useSmallDeviceCheck} from 'hooks';

const App = () => {
  const {smallDevice} = useSmallDeviceCheck();

  if (smallDevice === null) return <LinearProgress color='secondary' />; // Do not render until App has determined whether device is small or standard size
  return (
    <Box sx={rootStyles}>
      <AppBar />
      <Drawer />
      <Snackbar />
      <ErrorBoundary filePath='src/App.js'>
        <MainView />
      </ErrorBoundary>
    </Box>
  );
};

const rootStyles = {
  display: 'flex',
  flexGrow: 1,
  minHeight: '100vh',
  overflow: 'hidden',
  position: 'relative',
  zIndex: 1,
};
export default App;
