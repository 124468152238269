import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {acGetDashboard} from 'state/dashboard/actions';

const useDashboard = () => {
  const dispatch = useDispatch();
  const {dailyRegister, pending, touched} = useSelector(state => state.dashboard);

  useEffect(() => {
    if (!touched) dispatch(acGetDashboard);
  }, [dispatch, touched]);

  return {pending, touched, dailyRegister};
};

export default useDashboard;
