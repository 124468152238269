import {SET_PENDING, SET_TOUCHED, SET_DAILY_REGISTER, FORGET_DASHBOARD} from './types';
import {DateTime} from 'luxon';
import {Auth, Firestore} from 'firebaseConfig';
const snapOptions = {includeMetadataChanges: true};
const findDocIndex = (array, id) => array.findIndex(item => item.DocId === id);

const dailyRegisterList = [];
let dailyRegisterUnsubscribe = null;
export const acGetDashboard = (dispatch, getState) => {
  // Used to prevent additional executions; This AC should only run when touched property is false;
  dispatch({type: SET_TOUCHED, payload: true});

  // Use Auth listener to prevent unauthorized access from non-users; Also clears data when user logs off;
  Auth.onAuthStateChanged(user => {
    if (user) {
      const todayStart = DateTime.local().startOf('day').toUTC().toJSDate();
      const todayEnd = DateTime.local().endOf('day').toUTC().toJSDate();
      const handleSnap = snap => {
        const docChangeSize = snap.docChanges().length;
        const isPending = getState().dashboard.pending;

        // If collection is empty and redux state is pending, then set pending to false to show first snap performed
        if (docChangeSize === 0 && isPending) dispatch({type: SET_PENDING, payload: false});

        // If 1 or more doc change in snap, then loop through changes and dispatch to redux state
        else if (docChangeSize >= 1) {
          for (let i = 0; i < docChangeSize; i++) {
            const {type, doc} = snap.docChanges()[i];
            const docData = {...doc.data(), DocId: doc.id};
            if (type === 'added') dailyRegisterList.push(docData);
            if (type === 'modified') dailyRegisterList.splice(findDocIndex(dailyRegisterList, doc.id), 1, docData);
            if (type === 'removed') dailyRegisterList.splice(findDocIndex(dailyRegisterList, doc.id), 1);
          };
          dispatch({type: SET_DAILY_REGISTER, payload: [...dailyRegisterList]});
          dispatch({type: SET_PENDING, payload: false});
        }
      };
      const handleError = error => console.log('acGetDashboard Error: ', error.message, {error});
      const query = Firestore.query(
        Firestore.collection('Register'),
        Firestore.where('RegDetails.On', '>', todayStart),
        Firestore.where('RegDetails.On', '<', todayEnd),
      );
      dailyRegisterUnsubscribe = Firestore.onSnapshot(query, snapOptions, handleSnap, handleError);
    }
    else {
      dispatch({type: FORGET_DASHBOARD});
      if (dailyRegisterUnsubscribe) dailyRegisterUnsubscribe();
    }
  });
};
