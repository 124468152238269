import {useCallback} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {acSetColumnState, acSetFilterState} from 'state/agGrid/actions';


/**
 * A hook to connect ag-grid to global redux store in order to persist table state after navigating to a
 * different route.
 *
 * @param {object} tableId A unique identifier for the ag-grid table. Table state will be tied to this unique
 * identifier in redux, so failing to ensure `tableId` is unique could lead to overwritten or improperly
 * merged table state.
 * @return {object}
 */
const useAgGridRedux = (tableId) => {
  const dispatch = useDispatch();
  const agGrid = useSelector(state => state?.agGrid);

  /**
   * A handler function used to apply ag-grid column state with any column state previously saved to the
   * redux store. Handler should be connected to ag-grid's onFirstDataRendered event and must be passed that
   * event's `params` property.
   *
   * @param {object} params table params passed by ag-grid's onFirstDataRendered event.
   * @param {object} defaultColumnState fallback columnState that can be set programatically; this will be the
   * default columnState used if the user has not actively changes the columnState model yet.
   * @param {object} defaultFilterState fallback filterState that can be set programatically; this will be the
   * default filterState used if the user has not actively changes the filterState model yet.
   * @return {undefined}
   */
  const handleFirstData = useCallback(({params, defaultColumnState, defaultFilterState}) => {
    const agGridTable = agGrid[tableId];

    // If columnState exists (i.e. user has actively changed columnState model), then reapply that columnState on rerender of table;
    if (agGridTable?.columnState) params.columnApi.applyColumnState({state: agGridTable?.columnState});
    // Else if columnState does not exist, then apply defaultColumnState provided by developer (if available);
    else if (!agGridTable?.columnState && defaultColumnState) params.columnApi.applyColumnState(defaultColumnState);

    // If filterState exists (i.e. user has actively changed filterState model), then reapply that filterState on rerender of table;
    if (agGridTable?.filterState) params.api.setFilterModel(agGridTable.filterState);
    // Else if filterState does not exist, then apply defaultFilterState provided by developer (if available);
    else if (!agGridTable?.filterState && defaultFilterState) params.columnApi.setFilterModel(defaultFilterState);
  }, [agGrid, tableId]);

  /**
   * A handler function used to save ag-grid column state to redux store. Handler should be connected to
   * ag-grid events related to model updates, including onSortChanged and onFilterChanged, and must have
   * those event's `params` property passed to handler.
   *
   * @param {object} params table params passed by ag-grid's model update events.
   * @return {undefined}
   */
  const handleColumnChange = useCallback(params => {
    const newColumnState = params.columnApi.getColumnState();
    dispatch(acSetColumnState(tableId, newColumnState));
  }, [dispatch, tableId]);

  /**
   * A handler function used to save ag-grid filter state to redux store. Handler should be connected to
   * ag-grid's onFilterChanged event and must have that event's `params` property passed to handler.
   *
   * @param {object} params table params passed by ag-grid's onFilterChanged event.
   * @return {undefined}
   */
  const handleFilterChange = useCallback(params => {
    const filterModel = params.api.getFilterModel();
    dispatch(acSetFilterState(tableId, filterModel));
  }, [dispatch, tableId]);

  return {handleFirstData, handleColumnChange, handleFilterChange};
};

export default useAgGridRedux;
