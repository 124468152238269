import React from 'react'; // eslint-disable-line no-unused-vars
import {useDispatch} from 'react-redux';
import {acSetSnackbar} from 'state/ui/actions';

const useSnackbar = () => {
  const dispatch = useDispatch();
  const setSnackbar = (newSettings) => dispatch(acSetSnackbar(newSettings));
  const closeSnackbar = () => dispatch(acSetSnackbar({open: false}));

  return {setSnackbar, closeSnackbar};
};

export default useSnackbar;
