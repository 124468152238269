import {SET_PENDING, SET_TOUCHED, SET_SHIPPING_DOCS, FORGET_SHIPPING_DOCS} from './types';
import {Auth, Firestore} from 'firebaseConfig';
const snapOptions = {includeMetadataChanges: true};
const findDocIndex = (array, id) => array.findIndex(item => item.DocId === id);

const shippingDocsList = [];
const shippingDocsRef = {};
let shippingDocsUnsubscribe = null;
export const acGetShippingDocs = (dispatch, getState) => {
  // Used to prevent additional executions; This AC should only run when touched property is false;
  dispatch({type: SET_TOUCHED, payload: true});
  // Use Auth listener to prevent unauthorized access from non-users; Also clears data when user logs off;
  Auth.onAuthStateChanged(user => {
    if (user) {
      const handleSnap = snap => {
        const docChangeSize = snap.docChanges().length;
        const isPending = getState().shipping.pending;

        // If collection is empty and redux state is pending, then set pending to false to show first snap performed
        if (docChangeSize === 0 && isPending) dispatch({type: SET_PENDING, payload: false});

        // If 1 or more doc change in snap, then loop through changes and dispatch to redux state
        else if (docChangeSize >= 1) {
          for (let i = 0; i < docChangeSize; i++) {
            const {type, doc} = snap.docChanges()[i];
            const docData = {...doc.data(), DocId: doc.id};
            if (type === 'added') shippingDocsList.push(docData);
            if (type === 'modified') shippingDocsList.splice(findDocIndex(shippingDocsList, doc.id), 1, docData);
            if (type === 'removed') shippingDocsList.splice(findDocIndex(shippingDocsList, doc.id), 1);
            shippingDocsRef[doc.id] = docData;
          };
          const shippingDocs = {
            data: [...shippingDocsList],
            ref: shippingDocsRef,
          };
          dispatch({type: SET_SHIPPING_DOCS, payload: shippingDocs});
          dispatch({type: SET_PENDING, payload: false});
        }
      };
      const handleError = error => console.log('acGetShippingDocs Error: ', error.message, {error});
      const query = Firestore.collection('Shipping-Docs');
      shippingDocsUnsubscribe = Firestore.onSnapshot(query, snapOptions, handleSnap, handleError);
    }
    else {
      dispatch({type: FORGET_SHIPPING_DOCS});
      if (shippingDocsUnsubscribe) shippingDocsUnsubscribe();
    }
  });
};
