/* eslint-disable max-len */
import React from 'react';
import {SvgIcon} from '@mui/material';

const Return = (props) => (
  <SvgIcon viewBox='0 0 512 512' {...props}>
    <path d="m512 212.683c0 79.402-64.598 144-144 144h-258.745l32.008 32.008c12.497 12.496 12.497 32.758 0 45.255-6.249 6.248-14.438 9.372-22.627 9.372s-16.379-3.124-22.627-9.372l-86.636-86.635c-12.497-12.497-12.497-32.759 0-45.256l86.635-86.635c12.497-12.496 32.758-12.496 45.255 0 12.497 12.497 12.497 32.759 0 45.255l-32.008 32.008h258.745c44.112 0 80-35.888 80-80s-35.888-80-80-80h-218c-17.673 0-32-14.327-32-32s14.327-32 32-32h218c79.402 0 144 64.597 144 144z" />
  </SvgIcon>
);

export default Return;
