import {SET_PENDING, SET_TOUCHED, SET_ACCOUNTS, FORGET_ACCOUNTS} from './types';

const INITIAL_STATE = {
  all: [],
  active: [],
  inactive: [],
  ref: {},
  pending: true,
  touched: false,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = INITIAL_STATE, action) => {
  const {type, payload} = action;
  switch (type) {
  case SET_PENDING:
    return {...state, pending: payload};
  case SET_TOUCHED:
    return {...state, touched: payload};
  case SET_ACCOUNTS:
    return {...state, ...payload};
  case FORGET_ACCOUNTS:
    return {...INITIAL_STATE};
  default:
    return state;
  }
};
