import React from 'react';
import ReactDOM from 'react-dom';
import reportWebVitals from './reportWebVitals';

// Import Redux Elements
import {Provider as ReduxProvider} from 'react-redux';
import {store} from './state/store';

// Import Router Elements
import {BrowserRouter} from 'react-router-dom';
import PrimaryRoutes from './PrimaryRoutes';

// Import Design Elements
import {CssBaseline} from '@mui/material';
import {ThemeProvider, createTheme} from '@mui/material/styles';
import './index.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css'; // Apply to ag-grid by wrapping in <div className='ag-theme-alpine'>...</div>
import 'ag-grid-community/dist/styles/ag-theme-balham.css'; // Apply to ag-grid by wrapping in <div className='ag-theme-balham'>...</div>

const theme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#6D6E71',
      light: '#9b9c9f',
      dark: '#424346',
      contrastText: '#ffffff',
    },
    secondary: {
      main: '#ed1c24',
      light: '#ff5f4e',
      dark: '#b20000',
      contrastText: '#000000',
    },
    background: {
      paper: '#fff',
      default: '#eeeeee',
    },
  },
  shape: {borderRadius: 4},
});

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <CssBaseline />
      <ThemeProvider theme={theme}>
        <ReduxProvider store={store}>
          <PrimaryRoutes />
        </ReduxProvider>
      </ThemeProvider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
