import React from 'react';
import {Typography} from '@mui/material';
import {StyledPaper} from 'components';
import {ButtonPress} from 'icons';

const Placeholder = () => {
  return (
    <StyledPaper>
      <div style={{position: 'relative', textAlign: 'center'}}>
        <Typography variant='h4' sx={styles.placeholderText}>
          Choose Option Above to Continue
        </Typography>
        <ButtonPress sx={styles.placeholderIcon} />
      </div>
    </StyledPaper>
  );
};

const styles = {
  placeholderIcon: {
    fontSize: ['10rem', '10rem', '15rem', '15rem', '15rem'],
    color: 'primary.light',
    opacity: 0.3,
  },
  placeholderText: {
    alignItems: 'center',
    color: 'secondary.dark',
    display: 'flex',
    fontSize: ['1.5rem', '1.5rem', '2.125rem', '2.125rem', '2.125rem'],
    height: '100%',
    justifyContent: 'center',
    position: 'absolute',
    width: '100%',
  },
};
export default React.memo(Placeholder);
