import React, {Suspense} from 'react';
import {Switch, Redirect, Route} from 'react-router-dom';
import {LinearProgress} from '@mui/material';

// Lazy load routes to reduce main Webpack bundle size
const InboundOrder = React.lazy(() => import('./InboundOrder'));
const InboundLabels = React.lazy(() => import('./InboundLabels'));
const MovementForms = React.lazy(() => import('./MovementForms'));
const ReturnOrder = React.lazy(() => import('./ReturnOrder'));
const ReturnLabels = React.lazy(() => import('./ReturnLabels'));
const StockLabel = React.lazy(() => import('./StockLabel'));
const OutboundOrder = React.lazy(() => import('./OutboundOrder'));
const VisualInspection = React.lazy(() => import('./VisualInspection'));
const StateOfHealth = React.lazy(() => import('./StateOfHealth'));

const Print = () => {
  return (
    <Suspense fallback={<LinearProgress color='secondary' />}>
      <Switch>
        <Route exact={true} strict={true} path='/print/inbound-order/:DocId' component={InboundOrder} />;
        <Route exact={true} strict={true} path='/print/inbound-labels/:RegisterNo' component={InboundLabels} />;
        <Route exact={true} strict={true} path='/print/movement/:DocId' component={MovementForms} />;
        <Route exact={true} strict={true} path='/print/return-order/:DocId' component={ReturnOrder} />;
        <Route exact={true} strict={true} path='/print/return-labels/:RegisterNo' component={ReturnLabels} />;
        <Route exact={true} strict={true} path='/print/stock-label/:DocId' component={StockLabel} />;
        <Route exact={true} strict={true} path='/print/outbound-order/:DocId' component={OutboundOrder} />;
        <Route exact={true} strict={true} path='/print/work-order/visual-inspection/:DocId' component={VisualInspection} />;
        <Route exact={true} strict={true} path='/print/work-order/state-of-health/:DocId' component={StateOfHealth} />;
        <Redirect exact to={'/portal/dashboard'} />
      </Switch>
    </Suspense>
  );
};

export default Print;
