import React, {Suspense} from 'react';
import {Switch, Redirect, Route} from 'react-router-dom';
import {Box, LinearProgress} from '@mui/material';
import {useDrawer} from 'hooks';
import {routes} from 'routes';

const MainView = () => {
  const {drawerMargin} = useDrawer();

  if (!drawerMargin) return <LinearProgress color='secondary' />; // Do not load page until first drawerMargin is known; Prevents improper animation effect
  return (
    <Box component='main' sx={styles.mainContent}>
      <div style={drawerMargin}>
        <Suspense fallback={<LinearProgress color='secondary' />}>
          <Box sx={styles.mainPadding}>
            <Switch>
              {routes.map(({component, exact, path, strict}) => <Route key={path} exact={exact} path={path} strict={strict} component={component} />)}
              <Redirect exact to={'/portal/dashboard'} />
            </Switch>
          </Box>
        </Suspense>
      </div>
    </Box>
  );
};

const styles = {
  mainContent: {
    'flexGrow': 1,
    'backgroundColor': 'background.default',
    'minWidth': 0, // So the Typography noWrap works
    '@media print': {backgroundColor: 'background.paper'},
  },
  mainPadding: {
    p: {
      xs: '8px 16px 16px 16px',
      sm: '16px',
      lg: '24px',
    },
    maxWidth: '1920px',
  },
};
export default MainView;
