import {createStore, combineReducers, applyMiddleware} from 'redux';
import ReduxThunk from 'redux-thunk';

// Import redux reducers
import AccountsReducers from './accounts/reducers';
import AgGridReducers from './agGrid/reducers';
import CurrentUserReducers from './currentUser/reducers';
import DashboardReducers from './dashboard/reducers';
import UiReducers from './ui/reducers';
import WarehouseReducers from './warehouses/reducers';
import ShippingDocsReducers from './shipping/reducers';

// Initialize Redux Store
export const store = createStore(
  combineReducers({
    accounts: AccountsReducers,
    agGrid: AgGridReducers,
    currentUser: CurrentUserReducers,
    dashboard: DashboardReducers,
    ui: UiReducers,
    warehouses: WarehouseReducers,
    shipping: ShippingDocsReducers,
  }),
  applyMiddleware(ReduxThunk),
);
