import {useEffect, useState} from 'react';
import axios from 'axios';

const useBackgroundImage = () => {
  const [backgroundImage, setBackgroundImage] = useState(null);

  // Determine screen width and thereby the best background image size for user's device
  const screenWidth = window.screen.width;
  const backgroundSize = (() => {
    if (screenWidth >= 1920) return '1600x900';
    else if (screenWidth >= 1280) return '1280x720';
    else if (screenWidth >= 960) return '960x540';
    else return '640x360';
  })();

  useEffect(() => {
    const reqOptions = {
      method: 'get',
      url: `https://source.unsplash.com/user/dkinsbursky/${backgroundSize}/daily`,
      responseType: 'blob',
    };
    axios(reqOptions).then(response => {
      const objectURL = URL.createObjectURL(response.data);
      setBackgroundImage(objectURL);
    }).catch(error => {
      // Set backgroundImage to undefined to default to no image background in case of unsplash API failure
      setBackgroundImage(undefined);
    });
  }, [backgroundSize]);

  return backgroundImage;
};

export default useBackgroundImage;
