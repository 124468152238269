/* eslint-disable max-len */
import React from 'react';
import {SvgIcon} from '@mui/material';

const ShipInbound = (props) => (
  <SvgIcon viewBox='0 0 512 512' {...props}>
    <path d='M181.082,295.599c-7.811,7.809-7.811,20.473,0,28.284c7.811,7.811,20.474,7.809,28.284-0.001l53.74-53.74
      c7.87-7.869,7.711-20.573,0-28.284l-53.741-53.74c-7.81-7.811-20.473-7.81-28.284,0c-7.811,7.81-7.811,20.474,0,28.285
      L200.679,236L35,235.999c-11.045,0-20,8.954-20,20c0,11.046,8.954,20,20,20L200.68,276L181.082,295.599z' />
    <path d='M477,0H151c-11.046,0-20,8.954-20,20v76c0,11.046,8.954,20,20,20s20-8.954,20-20V40h286v432H171v-56
      c0-11.046-8.954-20-20-20s-20,8.954-20,20v76c0,11.046,8.954,20,20,20h326c11.046,0,20-8.954,20-20V20C497,8.954,488.046,0,477,0
      z' />
  </SvgIcon>
);

export default ShipInbound;
