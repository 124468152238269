/* eslint-disable max-len */
import React from 'react';
import {SvgIcon} from '@mui/material';

const Grab = (props) => (
  <SvgIcon viewBox='0 0 410.837 410.837' {...props}>
    <path d="M370.005,130.931L263.893,24.819c-18.859-18.88-49.557-18.88-68.416,0c-6.955,6.933-11.52,15.616-13.312,25.024 c-18.965-17.195-48.405-16.661-66.688,1.643c-7.872,7.893-12.48,17.835-13.76,28.139c-18.048-8.853-40.555-5.824-55.573,9.195 c-16.597,16.619-18.581,42.389-5.952,61.184c-9.835,1.643-18.88,6.293-26.048,13.483c-18.859,18.859-18.859,49.557,0,68.416 l34.027,34.027v38.251c0,52.928,43.072,96,96,96h105.152c31.339,0,60.8-12.203,82.965-34.368l37.717-37.717 c26.325-26.325,40.832-61.333,40.832-98.581S396.331,157.256,370.005,130.931z M354.923,313.011l-37.717,37.717 c-18.133,18.133-42.24,28.117-67.883,28.117H144.171c-41.173,0-74.667-33.493-74.667-74.667v-16.917l24.448,24.469 c4.16,4.16,10.923,4.16,15.083,0c2.069-2.091,3.115-4.821,3.115-7.552s-1.024-5.461-3.115-7.552l-79.808-79.808 c-5.099-5.099-7.915-11.904-7.915-19.115c0-7.211,2.816-14.016,7.915-19.115c10.24-10.219,28.032-10.219,38.251,0l26.475,26.475 c4.16,4.16,10.923,4.16,15.083,0c2.069-2.091,3.115-4.821,3.115-7.552c0-2.731-1.024-5.461-3.115-7.552l-47.808-47.808 c-10.539-10.539-10.539-27.712,0-38.251c10.539-10.539,27.712-10.539,38.251,0l47.808,47.829c4.16,4.16,10.923,4.16,15.083,0 c2.091-2.091,3.115-4.821,3.115-7.552c0-2.731-1.024-5.461-3.115-7.552l-31.808-31.808c-5.099-5.099-7.915-11.904-7.915-19.115 c0-7.211,2.816-14.016,7.915-19.115c10.539-10.539,27.712-10.539,38.251,0l31.808,31.808c4.16,4.16,10.923,4.16,15.083,0 c2.069-2.091,3.115-4.821,3.115-7.552c0-2.731-1.024-5.461-3.115-7.552l-5.141-5.141c-10.539-10.539-10.539-27.712,0-38.251 c10.24-10.219,28.032-10.219,38.251,0l106.112,106.112c22.293,22.293,34.581,51.968,34.581,83.499 S377.216,290.717,354.923,313.011z" />
  </SvgIcon>
);

export default Grab;
