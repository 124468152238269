import {useState, useCallback} from 'react';

export default function useCarousel() {
  const [carouselOpen, toggleCarousel] = useState(false);
  const [selectedImg, setSelected] = useState(0);
  const handleClose = useCallback((e) => {
    if (e.currentTarget === e.target) toggleCarousel(false);
  }, []);
  const handleOpen = useCallback((imgIndex) => {
    toggleCarousel(true);
    setSelected(imgIndex);
  }, []);

  return {carouselOpen, selectedImg, handleClose, handleOpen};
};
