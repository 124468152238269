import {SET_PENDING, SET_TOUCHED, SET_WAREHOUSES, FORGET_WAREHOUSES} from './types';
import {Auth, Firestore} from 'firebaseConfig';
const snapOptions = {includeMetadataChanges: true};
const findDocIndex = (array, id) => array.findIndex(item => item.DocId === id);

const warehouseList = [];
const warehouseRef = {};
let warehouseUnsubscribe = null;
export const acGetWarehouses = (dispatch, getState) => {
  // Used to prevent additional executions; This AC should only run when touched property is false;
  dispatch({type: SET_TOUCHED, payload: true});

  // Use Auth listener to prevent unauthorized access from non-users; Also clears data when user logs off;
  Auth.onAuthStateChanged(user => {
    if (user) {
      const handleSnap = snap => {
        const docChangeSize = snap.docChanges().length;
        const isPending = getState().warehouses.pending;

        // If collection is empty and redux state is pending, then set pending to false to show first snap performed
        if (docChangeSize === 0 && isPending) dispatch({type: SET_PENDING, payload: false});

        // If 1 or more doc change in snap, then loop through changes and dispatch to redux state
        else if (docChangeSize >= 1) {
          for (let i = 0; i < docChangeSize; i++) {
            const {type, doc} = snap.docChanges()[i];
            const docData = {...doc.data(), DocId: doc.id};
            if (type === 'added') warehouseList.push(docData);
            if (type === 'modified') warehouseList.splice(findDocIndex(warehouseList, doc.id), 1, docData);
            if (type === 'removed') warehouseList.splice(findDocIndex(warehouseList, doc.id), 1);
            warehouseRef[doc.id] = docData;
          };
          const warehouses = {
            all: [...warehouseList],
            active: warehouseList.filter(item => item.Active),
            inactive: warehouseList.filter(item => !item.Active),
            ref: warehouseRef,
          };
          dispatch({type: SET_WAREHOUSES, payload: warehouses});
          dispatch({type: SET_PENDING, payload: false});
        }
      };
      const handleError = error => console.log('acGetWarehouses Error: ', error.message, {error});
      const query = Firestore.collection('Warehouses');
      warehouseUnsubscribe = Firestore.onSnapshot(query, snapOptions, handleSnap, handleError);
    }
    else {
      dispatch({type: FORGET_WAREHOUSES});
      if (warehouseUnsubscribe) warehouseUnsubscribe();
    }
  });
};
