import React, {useCallback} from 'react';
import PropTypes from 'prop-types';
import {Backdrop, Box, Button} from '@mui/material';
import {ArrowLeft, ArrowRight} from '@mui/icons-material';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import {Carousel} from 'react-responsive-carousel';

const ImageCarousel = ({images, open, handleClose, showDescription, selectedImg}) => {
  const handleArrowPrev = useCallback((onClickHandler, hasPrev, label) => {
    return hasPrev && (
      <Button onClick={onClickHandler} title={label} sx={styles.arrowLeft}>
        <ArrowLeft sx={styles.icon} />
      </Button>
    );
  }, []);
  const handleArrowNext = useCallback((onClickHandler, hasNext, label) => {
    return hasNext && (
      <Button onClick={onClickHandler} title={label} sx={styles.arrowRight}>
        <ArrowRight sx={styles.icon} />
      </Button>
    );
  }, []);

  return (
    <Backdrop sx={styles.backdrop} open={open} onClick={handleClose}>
      <Box sx={styles.carouselWrapper}>
        <Carousel selectedItem={selectedImg} renderArrowPrev={handleArrowPrev} renderArrowNext={handleArrowNext}>
          {images && images.map(image => (
            <div key={image.FullPath}>
              <img src={image.DownloadURL} alt={image.Description} />
              {showDescription && <Box component='span' sx={styles.description}>{image.Description}</Box>}
            </div>
          ))}
        </Carousel>
      </Box>
    </Backdrop>
  );
};

const styles = {
  arrowLeft: {
    'backgroundColor': 'rgba(120, 120, 120, 0.4)',
    'cursor': 'pointer',
    'height': '100%',
    'left': 0,
    'position': 'absolute',
    'top': 0,
    'zIndex': 2,
    '&:hover': {backgroundColor: 'rgba(0, 0, 0, 0.5)'},
  },
  arrowRight: {
    'backgroundColor': 'rgba(120, 120, 120, 0.4)',
    'cursor': 'pointer',
    'height': '100%',
    'position': 'absolute',
    'right': 0,
    'top': 0,
    'zIndex': 2,
    '&:hover': {backgroundColor: 'rgba(0, 0, 0, 0.5)'},
  },
  backdrop: {
    zIndex: theme => theme.zIndex.drawer + 1,
    color: '#fff',
  },
  carouselWrapper: {width: '70%'},
  description: {
    position: 'absolute',
    marginLeft: 'auto',
    marginRight: 'auto',
    left: 0,
    right: 0,
    textAlign: 'center',
    bottom: '45px',
    fontSize: '1.25em',
  },
  icon: {fontSize: '48px', color: '#fff'},
};
ImageCarousel.propTypes = {
  images: PropTypes.array,
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  showDescription: PropTypes.bool,
  selectedImg: PropTypes.number,
};
export default ImageCarousel;
