import {SET_PENDING, SET_TOUCHED, SET_DAILY_REGISTER, FORGET_DASHBOARD} from './types';

const INITIAL_STATE = {
  dailyRegister: [],
  pending: true,
  touched: false,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = INITIAL_STATE, action) => {
  const {type, payload} = action;
  switch (type) {
  case SET_PENDING:
    return {...state, pending: payload};
  case SET_TOUCHED:
    return {...state, touched: payload};
  case SET_DAILY_REGISTER:
    return {...state, dailyRegister: payload};
  case FORGET_DASHBOARD:
    return {...INITIAL_STATE};
  default:
    return state;
  }
};
