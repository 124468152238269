import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {acGetAccounts} from 'state/accounts/actions';

const useAccounts = () => {
  const dispatch = useDispatch();
  const {all, active, inactive, ref, pending, touched} = useSelector(state => state.accounts);

  useEffect(() => {
    if (!touched) dispatch(acGetAccounts);
  }, [dispatch, touched]);

  return {pending, touched, all, active, inactive, ref};
};

export default useAccounts;
