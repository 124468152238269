import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {Route, Redirect} from 'react-router-dom';
import {useDispatch} from 'react-redux';
import {Auth, Functions} from 'firebaseConfig';
import {LinearProgress} from '@mui/material';
import {acSetCurrentUser, acForgetCurrentUser} from 'state/currentUser/actions';
// An array of roles that should be granted access to portal based on user's roleXevStrategies custom claim
const authorizedRoles = ['Admin', 'Manager', 'User'];

const PrivateRoute = props => {
  const dispatch = useDispatch();
  const [auth, setAuth] = useState({checked: false, user: null});

  useEffect(() => {
    let authTimer = null;
    const unsubscribe = Auth.onIdTokenChanged(async user => {
      // If no user found, set checked to true and user to null, which will serve to return <Redirect to={'/signin'} />
      if (!user) {
        dispatch(acForgetCurrentUser());
        setAuth({checked: true, user: null});
      }

      // If a user is found...
      else if (user) {
        // retrieve all standard and custom claims from user's Id Token
        const {claims} = await Auth.currentUser().getIdTokenResult();
        dispatch(acSetCurrentUser(claims));

        // Check to see if user record contains an allowable xEV Strategies custom claim role...
        if (authorizedRoles.includes(claims?.roleXevStrategies)) {
          // if so, set checked to true and user to resulting claims, which will serve to return private route as <Route path={props.path} component={props.component} />
          setAuth({checked: true, user: claims});

          // Also setInteral to recheck a user's local firebase project authentication against external corporate firebase authentication;
          // User should be signed out from local project if they are no longer authenticated in external corporate project;
          if (process.env.NODE_ENV !== 'development') {
            const minutes15 = 1000 * 60 * 15;
            const authCheck = async () => {
              try {
                const {data: userRecord} = await Functions.httpsCallable('corpUserRecheckAuthentication')({uid: user.uid});
                if (!userRecord || userRecord.disabled) {
                  alert(`Portal authentication has been disabled by an adminstrator.`);
                  Auth.signOut();
                }
                else if (!authorizedRoles.includes(claims?.roleXevStrategies)) {
                  alert(`Your user account has not been authorized to access this page. If you need access, please contact a site administrator.`);
                  Auth.signOut();
                }
              }
              catch (error) {
                alert(`Auth revalidation error: ${error.message}`);
                Auth.signOut();
              }
            };
            // Trigger authCheck immediately and then every 15 minutes thereafter
            authCheck();
            authTimer = setInterval(authCheck, minutes15);
          }
        }
        else {
          // If no access granted, throw alert message and signout the user which will serve to return <Redirect to={'/signin'} />
          alert('Your user account has not been authorized to access this page. If you need access, please contact a site administrator.');
          Auth.signOut();
        }
      }
    });
    return () => {
      if (unsubscribe) unsubscribe();
      if (authTimer) clearInterval(authTimer);
      setAuth({checked: true, user: null});
      dispatch(acForgetCurrentUser());
    };
  }, [dispatch]);

  if (auth.checked && auth.user) return <Route path={props.path} component={props.component} />;
  else if (auth.checked && !auth.user) return <Redirect to={'/signin'} />;
  else return <LinearProgress />;
};

PrivateRoute.propTypes = {component: PropTypes.elementType, path: PropTypes.string};
export default PrivateRoute;
