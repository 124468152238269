import {useCallback} from 'react';
import {Auth, Functions} from 'firebaseConfig';
import {useHistory} from 'react-router-dom';

const useSubmitAuth = () => {
  // Destructure only push function from react-router history object
  // Prevent useCallback from retriggering on history object update
  const {push: routePush} = useHistory();

  const handleSignIn = useCallback(async (values, actions) => {
    const {Email, Password} = values;
    try {
      // Send Email/Password server-side to authenticate against kbi-corp-auth Firebase Project; Will return customToken as result.data
      const {data: {customToken, claims}} = await Functions.httpsCallable('corpUserAuthenticate')({Email, Password});

      // Sign in user with customToken; Return the signed in user's credential
      const credential = await Auth.signInWithCustomToken(customToken);

      // Update local xev-strategies user's displayName with returned kbi-corp-auth displayName
      await Auth.updateProfile({displayName: claims?.displayName});

      // Update local xev-strategies user's email with returned kbi-corp-auth email
      await Auth.updateEmail(claims?.email);

      // Update local xev-strategies user's customClaims with necessary, returned kbi-corp-auth customClaims
      await Functions.httpsCallable('corpUserSetClaims')({uid: credential.user.uid, claims: {SecurityId: claims?.SecurityId, roleXevStrategies: claims?.roleXevStrategies}});

      // Use getIdToken method to refresh token with newly added custom claims
      await Auth.currentUser().getIdToken(true);

      routePush('/portal/dashboard');
    }
    catch (error) {
      actions.setStatus({text: error.message, in: true, severity: 'error'});
      actions.setSubmitting(false);
    }
  }, [routePush]);

  const handleForgotPassword = useCallback(async (values, actions) => {
    const {Email} = values;
    try {
      await Functions.httpsCallable('corpUserResetPassword')({Email});
      actions.setStatus({text: 'An password reset email has been sent.', in: true, severity: 'success'});
      actions.setSubmitting(false);
    }
    catch (error) {
      actions.setStatus({text: error.message, in: true, severity: 'error'});
      actions.setSubmitting(false);
    }
  }, []);

  return {handleSignIn, handleForgotPassword};
};

export default useSubmitAuth;
