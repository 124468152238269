import React, {useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import {Alert, Box, Grid, Paper, Typography} from '@mui/material';
import {FormikForm, SubmitButton, PasswordField, TextField} from '@kbi/story-forms';
import {object, string} from 'yup';
import logo from 'media/xEVLogoTag.svg';
import {useSubmitAuth} from './hooks';

const ForgotPassword = ({stage, setStage}) => {
  const forgotPasswordBtnProps = useMemo(() => ({
    gutterBottom: true,
    style: {
      cursor: 'pointer',
      float: 'right',
      marginTop: '4px',
    },
    variant: 'caption',
    onClick: () => {
      if (stage === 'SignIn') setStage('ForgotPassword');
      else if (stage === 'ForgotPassword') setStage('SignIn');
    },
  }), [stage, setStage]);
  return (
    <Typography {...forgotPasswordBtnProps}>
      {stage === 'SignIn' && 'Forgot Password?'}
      {stage === 'ForgotPassword' && 'Remembered Password?'}
    </Typography>
  );
};
ForgotPassword.propTypes = {stage: PropTypes.string.isRequired, setStage: PropTypes.func.isRequired};

const SignInForm = () => {
  const [stage, setStage] = useState('SignIn');
  const {handleSignIn, handleForgotPassword} = useSubmitAuth();
  const formProps = useMemo(() => ({
    initialStatus: {text: '', in: false, severity: 'warning'},
    initialValues: {Email: '', Password: ''},
    onSubmit: (values, actions) => {
      if (stage === 'SignIn') handleSignIn(values, actions);
      else if (stage === 'ForgotPassword') handleForgotPassword(values, actions);
    },
    validationSchema: () => {
      const forgotPasswordValidation = object().shape({
        Email: string().required().email(),
      });
      const signInValidation = object().shape({
        Email: string().required().email(),
        Password: string().required().min(5),
      });
      if (stage === 'ForgotPassword') return forgotPasswordValidation;
      if (stage === 'SignIn') return signInValidation;
    },
  }), [handleSignIn, handleForgotPassword, stage]);

  return (
    <Box sx={styles.layout}>
      <Paper sx={styles.paper} elevation={4}>
        <Box sx={styles.logoWrapper}>
          <Box component='img' alt='EV Battery Returns Logo' src={logo} sx={styles.logo} />
        </Box>
        <Typography variant='h5' align='center' sx={styles.headerText}>Inventory Portal</Typography>
        <Typography variant='h5' align='center' sx={styles.headerText} gutterBottom>User Authentication</Typography>
        <FormikForm {...formProps}>
          {(formik) => (<Grid container spacing={1}>
            <Grid item xs={12}>
              <TextField name='Email' required autoComplete='username' />
            </Grid>
            {stage === 'SignIn' && <Grid item xs={12}>
              <PasswordField name='Password' required autoComplete='current-password' />
            </Grid>}
            {Boolean(formik.status.text) && (<Grid item xs={12}>
              <Alert severity={formik.status.severity || 'info'} style={{margin: '8px 0px'}}>
                {formik.status.text}
              </Alert>
            </Grid>)}
            <Grid item xs={12}>
              <div>
                <SubmitButton fullWidth>
                  {stage === 'SignIn' && 'Sign In'}
                  {stage === 'ForgotPassword' && 'Reset Password'}
                </SubmitButton>
                <ForgotPassword stage={stage} setStage={setStage} />
              </div>
            </Grid>
          </Grid>)}
        </FormikForm>
      </Paper>
    </Box>
  );
};

const styles = {
  headerText: {fontSize: '1.2rem'},
  layout: {
    alignItems: 'flex-start',
    display: 'flex',
    height: '100vh',
    justifyContent: 'center',
    overflowY: 'auto',
    position: 'absolute',
    top: 0,
    width: '100vw',
  },
  logo: {
    height: '100px',
    marginBottom: '24px',
    marginTop: '24px',
  },
  logoWrapper: {display: 'flex', justifyContent: 'center'},
  paper: {
    border: '1px solid lightgray',
    display: 'flex',
    flexDirection: 'column',
    width: '400px',
    mb: 3,
    mt: 6,
    p: theme => `${theme.spacing(2)} ${theme.spacing(3)} ${theme.spacing(3)}`,
  },
};
export default SignInForm;
