import React from 'react';
import {Paper} from '@mui/material';
import PropTypes from 'prop-types';
import {useMediaQuery} from 'react-responsive';

const StyledPaper = props => {
  const {children, ...rest} = props;
  const isPrintView = useMediaQuery({query: 'print'});
  return <Paper elevation={isPrintView ? 0 : 3} {...rest}>{children}</Paper>;
};

StyledPaper.defaultProps = {
  style: {padding: '16px'},
};
StyledPaper.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.element, PropTypes.func, PropTypes.object, PropTypes.string]),
};
export default StyledPaper;
