import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {acGetShippingDocs} from 'state/shipping/actions';

const useShippingDocs = () => {
  const dispatch = useDispatch();
  const {data, ref, pending, touched} = useSelector(state => state.shipping);

  useEffect(() => {
    if (!touched) dispatch(acGetShippingDocs);
  }, [dispatch, touched]);

  return {pending, touched, data, ref};
};

export default useShippingDocs;
