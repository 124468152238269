import React from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';
import {PrivateRoute} from 'components';

// Route Imports
import App from './App';
import ErrorBoundary from './views/ErrorBoundary';
import SignIn from './views/SignIn';
import Print from './prints/index';

const PrimaryRoutes = () => {
  return (
    <Switch>
      <Route exact path='/signin' component={SignIn} />
      <PrivateRoute path='/error' component={ErrorBoundary} />
      <PrivateRoute path='/portal' component={App} />
      <PrivateRoute path='/print' component={Print} />
      <Redirect exact to='/portal' />
    </Switch>
  );
};

export default PrimaryRoutes;
