import {SET_CURRENT_USER, FORGET_CURRENT_USER} from './types';

const INITIAL_STATE = null;

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = INITIAL_STATE, action) => {
  const {type, payload} = action;
  switch (type) {
  case SET_CURRENT_USER:
    return payload;
  case FORGET_CURRENT_USER:
    return INITIAL_STATE;
  default:
    return state;
  }
};
