import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {acGetWarehouses} from 'state/warehouses/actions';

const useWarehouses = () => {
  const dispatch = useDispatch();
  const {all, active, inactive, pending, touched} = useSelector(state => state.warehouses);

  useEffect(() => {
    if (!touched) dispatch(acGetWarehouses);
  }, [dispatch, touched]);

  return {pending, touched, all, active, inactive};
};

export default useWarehouses;
