import React, {useMemo} from 'react';
import {Box, LinearProgress} from '@mui/material';
import {ErrorBoundary} from 'components';
import useBackgroundImage from './hooks/useBackgroundImage';
import SignInForm from './SignInForm';

const SignIn = () => {
  const backgroundImage = useBackgroundImage();
  const backgroundStyle = useMemo(() => ({
    backgroundSize: 'cover',
    backgroundImage: `url(${backgroundImage})`,
    opacity: 0.7,
    height: '100vh',
    width: '100vw',
  }), [backgroundImage]);

  if (!backgroundImage) return <LinearProgress color='secondary' />;
  return (
    <ErrorBoundary filePath='src/views/SignIn/index.js'>
      <div style={backgroundStyle} />
      <Box sx={styles.overlay} />
      <SignInForm />
    </ErrorBoundary>
  );
};

const styles = {
  overlay: {
    backgroundColor: '#ED1C24',
    opacity: 0.3,
    height: '100vh',
    width: '100vw',
    position: 'absolute',
    top: 0,
  },
};
export default React.memo(SignIn);
