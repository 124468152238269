import React from 'react';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router';
import {DateTime} from 'luxon';
import {Firestore} from 'firebaseConfig';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {hasError: false};
  }

  componentDidCatch(error, errorInfo) {
    const emailTemplate = {
      to: 'daniel@kbirecycling.com',
      template: {
        name: 'ErrorBoundary',
        data: {
          errorName: error?.name || 'No error name found.',
          errorMessage: error?.message || 'No error message found.',
          errorTime: DateTime.now().toLocaleString(DateTime.DATETIME_MED),
          filePath: this?.props?.filePath || 'No file path found.',
          urlPath: this?.props?.location?.pathname || 'No URL path found.',
          errorStack: error?.stack || 'No error stack found.',
        },
      },
    };
    Firestore.addDoc(Firestore.collection('Emails'), emailTemplate);
    this.setState({hasError: true});
    alert('Fatal System Error');
    this.props.history.push('/error');
  }

  render() {
    // When not in development environment, redirect user to ErrorBoundary view;
    if (this.state.hasError && process.env.NODE_ENV !== 'development') return <div />;
    // Else return child components of ErrorBoundary
    return this.props.children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  filePath: PropTypes.string.isRequired,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};
export default withRouter(ErrorBoundary);
