import React, {forwardRef, useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import {useHistory, useLocation} from 'react-router-dom';
import {ListItemButton, ListItemIcon, ListItemText} from '@mui/material';
import {acSetDrawerOpen} from 'state/ui/actions.js';

const MenuItem = ({icon, link, text}) => {
  const smallDevice = useSelector(state => state.ui.smallDevice);
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const CustomLink = useMemo(() => {
    const handleClick = e => {
      e.preventDefault();
      // Prevents same path being added to history multiple times.
      if (location.pathname !== link) {
        history.push(link);
        // Close Drawer, on small devices only, when new menu item link is clicked.
        if (smallDevice) dispatch(acSetDrawerOpen(false));
      }
    };
    // Makes ListItem function more like <a /> tag.
    return forwardRef((linkProps, ref) => <Link ref={ref} to={link} {...linkProps} onClick={handleClick} />);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, location.pathname, link, smallDevice]);
  const isSelected = useMemo(() => {
    return location.pathname.lastIndexOf(link, 0) === 0;
  }, [link, location]);

  return (
    <ListItemButton disableRipple component={CustomLink} sx={styles.listItemButton} selected={isSelected}>
      <ListItemIcon sx={styles.listItemIcon}>{icon}</ListItemIcon>
      <ListItemText primary={text} sx={styles.listItemTextPrimary} />
    </ListItemButton>
  );
};

const styles = {
  listItemButton: {
    'margin': '4px 0px',
    ':hover': {
      background: `rgba(237, 28, 36, 0.25)`,
      borderRadius: '4px',
    },
    '& .MuiListItemIcon-root': {color: 'inherit'},
  },
  listItemIcon: {
    '& .MuiSvgIcon-root': {color: 'inherit'},
  },
  listItemTextPrimary: {
    '& .MuiTypography-root': {color: 'text.primary', fontSize: '16px'},
  },
};
MenuItem.propTypes = {
  icon: PropTypes.node.isRequired,
  link: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};
export default MenuItem;
