/* eslint-disable max-len */
import React from 'react';
import {SvgIcon} from '@mui/material';

const Inventory = (props) => (
  <SvgIcon viewBox='0 0 512 512' {...props}>
    <path d='M180.572,271.12 180.572,321.148 150.572,321.148 150.572,271.12 90.072,271.12 90.072,392.12 241.072,392.12 241.072,271.12z' />
    <path d='M361.428,271.12 361.428,321.148 331.428,321.148 331.428,271.12 270.928,271.12 270.928,392.12 421.928,392.12 421.928,271.12z' />
    <path d='M180.572,30.024 180.572,80.051 150.572,80.051 150.572,30.024 90.072,30.024 90.072,151.024 241.072,151.024 241.072,30.024z' />
    <path d='M361.428,30.024 361.428,80.051 331.428,80.051 331.428,30.024 270.928,30.024 270.928,151.024 421.928,151.024 421.928,30.024z' />
    <path d='M512,241.072v-60.048h-30h-0.023V0h-30v181.023H60.025V0h-30v181.023H30H0v60.048h30.025V422.12H30H0v60.048h30.025V512 h30v-29.832h391.952V512h30v-29.832H512V422.12h-30h-0.023V241.072H512z M451.977,422.12H60.025V241.072h391.952V422.12z' />
  </SvgIcon>
);

export default Inventory;
