/* eslint-disable max-len */
import React from 'react';
import {SvgIcon} from '@mui/material';

const Hand = (props) => (
  <SvgIcon viewBox='0 0 507.072 507.072' {...props}>
    <path d="M466.24,221.789L274.624,30.173c-18.859-18.88-49.557-18.88-68.416,0c-7.083,7.083-11.499,15.829-13.269,24.981 l-3.648-3.648c-18.859-18.88-49.557-18.88-68.416,0c-7.104,7.104-11.541,15.872-13.291,25.045 C88.64,59.336,59.179,59.869,40.875,78.173s-18.859,47.723-1.643,66.688c-9.429,1.813-18.091,6.379-25.024,13.312 C5.056,167.304,0,179.443,0,192.371s5.056,25.088,14.187,34.219l160.917,160.917l-85.973,12.288 c-29.291,4.181-51.392,29.653-51.392,59.264c0,17.643,14.357,32,32,32h275.819c31.339,0,60.8-12.224,82.965-34.389l37.717-37.717 c26.325-26.325,40.832-61.333,40.832-98.581S492.565,248.115,466.24,221.789z M451.179,403.891l-37.717,37.717 c-18.133,18.133-42.24,28.117-67.883,28.117H69.76c-5.888,0-10.667-4.779-10.667-10.667c0-19.051,14.229-35.456,33.088-38.144 l107.093-15.296c3.989-0.576,7.317-3.349,8.597-7.189c1.28-3.84,0.277-8.064-2.581-10.923l-176-176 c-10.539-10.539-10.539-27.712,0-38.251c5.269-5.269,12.181-7.915,19.115-7.915c6.933,0,13.845,2.645,19.157,8l133.333,133.333 c4.16,4.16,10.923,4.16,15.083,0c2.069-2.091,3.115-4.821,3.115-7.552c0-2.731-1.024-5.461-3.115-7.552l-160-160 c-10.539-10.539-10.539-27.712,0-38.251c10.539-10.539,27.712-10.539,38.251,0l160,160c4.16,4.16,10.923,4.16,15.083,0 c2.069-2.091,3.115-4.821,3.115-7.552c0-2.731-1.024-5.461-3.115-7.552L135.979,104.883c-10.539-10.539-10.539-27.712,0-38.251 c10.24-10.219,28.032-10.219,38.251,0l133.333,133.333c4.16,4.16,10.923,4.16,15.083,0c2.069-2.091,3.115-4.821,3.115-7.552 c0-2.731-1.024-5.461-3.115-7.552L221.312,83.528c-10.539-10.539-10.539-27.712,0-38.251c10.539-10.539,27.712-10.539,38.251,0 l191.616,191.616c22.293,22.293,34.581,51.968,34.581,83.499S473.472,381.597,451.179,403.891z" />
  </SvgIcon>
);

export default Hand;
