import {SET_PENDING, SET_TOUCHED, SET_ACCOUNTS, FORGET_ACCOUNTS} from './types';
import {Auth, Firestore} from 'firebaseConfig';
const snapOptions = {includeMetadataChanges: true};
const findDocIndex = (array, id) => array.findIndex(item => item.DocId === id);

const accountList = [];
const accountRef = {};
let accountUnsubscribe = null;
export const acGetAccounts = (dispatch, getState) => {
  // Used to prevent additional executions; This AC should only run when touched property is false;
  dispatch({type: SET_TOUCHED, payload: true});

  // Use Auth listener to prevent unauthorized access from non-users; Also clears data when user logs off;
  Auth.onAuthStateChanged(user => {
    if (user) {
      const handleSnap = snap => {
        const docChangeSize = snap.docChanges().length;
        const isPending = getState().accounts.pending;

        // If collection is empty and redux state is pending, then set pending to false to show first snap performed
        if (docChangeSize === 0 && isPending) dispatch({type: SET_PENDING, payload: false});

        // If 1 or more doc change in snap, then loop through changes and dispatch to redux state
        else if (docChangeSize >= 1) {
          for (let i = 0; i < docChangeSize; i++) {
            const {type, doc} = snap.docChanges()[i];
            const docData = {...doc.data(), DocId: doc.id};
            if (type === 'added') accountList.push(docData);
            if (type === 'modified') accountList.splice(findDocIndex(accountList, doc.id), 1, docData);
            if (type === 'removed') accountList.splice(findDocIndex(accountList, doc.id), 1);
            accountRef[doc.id] = docData;
          };
          const accounts = {
            all: [...accountList],
            active: accountList.filter(item => item.Active),
            inactive: accountList.filter(item => !item.Active),
            ref: accountRef,
          };
          dispatch({type: SET_ACCOUNTS, payload: accounts});
          dispatch({type: SET_PENDING, payload: false});
        }
      };
      const handleError = error => console.log('acGetAccounts Error: ', error.message, {error});
      const query = Firestore.collection('Accounts');
      accountUnsubscribe = Firestore.onSnapshot(query, snapOptions, handleSnap, handleError);
    }
    else {
      dispatch({type: FORGET_ACCOUNTS});
      if (accountUnsubscribe) accountUnsubscribe();
    }
  });
};
