import React, {useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Box, Drawer as MuiDrawer, Divider, List, Typography} from '@mui/material';
import xEVLogo from 'media/xEVLogoTag.svg';
import {acSetDrawerOpen} from 'state/ui/actions.js';
import {menuItems} from 'routes';
import {LogoutItem, MenuItem} from './Drawer/index';

const Drawer = () => {
  const drawerOpen = useSelector(state => state.ui.drawerOpen);
  const smallDevice = useSelector(state => state.ui.smallDevice);
  const currentUser = useSelector(state => state.currentUser);
  const dispatch = useDispatch();
  const muiDrawerProps = useMemo(() => ({
    onClose: () => {
      if (smallDevice) dispatch(acSetDrawerOpen(false));
    },
    open: drawerOpen,
    sx: styles.drawerPaper,
    variant: smallDevice ? 'temporary' : 'persistent',
  }), [dispatch, drawerOpen, smallDevice]);

  return (
    <MuiDrawer {...muiDrawerProps}>
      <Box sx={styles.logo}><img src={xEVLogo} alt='xEV Strategies Logo' /></Box>
      <Typography sx={styles.title}>Inventory Portal</Typography>
      <Typography sx={styles.email}>{currentUser?.email || 'Pending'}</Typography>
      <Divider sx={styles.divider} />
      <List sx={styles.list}>
        {menuItems(currentUser).map(item => (
          <MenuItem key={item.text} icon={item.icon()} link={item.link} text={item.text} />
        ))}
        <LogoutItem />
      </List>
    </MuiDrawer>
  );
};

const leftOffset = '32px';
const drawerEase = theme => {
  const transition = theme.transitions.create('all', {
    easing: theme.transitions.easing.sharp,
    duration: 10000, // theme.transitions.duration.enteringScreen,
    delay: 10000,
  });
  return transition;
};
const styles = {
  divider: {height: '1px'},
  drawerPaper: {
    '& .MuiDrawer-paper': {
      background: `linear-gradient(180deg, rgba(255, 255, 255, 0.98) 0%, rgba(255, 255, 255, 0.7) 100%), url('https://source.unsplash.com/1600x900/?field')`, // eslint-disable-line max-len
      backgroundSize: 'cover',
      boxShadow: '1px 0px 8px 2px rgba(0,0,0,0.25);',
      minHeight: '100vh',
      paddingTop: ['0px', '0px', '0px', '0px', '56px'],
      transition: theme => ({
        sm: drawerEase(theme),
        md: drawerEase(theme),
        lg: drawerEase(theme),
        xl: drawerEase(theme),
      }),
      whiteSpace: 'nowrap',
      width: ['75vw', '50vw', '300px'],
    },
  },
  email: {
    fontSize: '14px',
    fontHeight: 'normal',
    lineHeight: '20px',
    paddingLeft: leftOffset,
    paddingBottom: '13px',
  },
  list: {
    'color': 'primary.dark',
    'padding': '16px 8px',
    '& .MuiListItemButton-root.Mui-selected': {
      background: `rgba(237, 28, 36, 0.25)`,
      borderRadius: '4px',
      color: 'secondary.main',
    },
    '& .MuiListItemButton-root.Mui-selected:hover': {
      background: `rgba(237, 28, 36, 0.25)`,
      borderRadius: '4px',
      color: 'secondary.main',
    },
  },
  logo: {
    width: '175px',
    height: '100px',
    margin: leftOffset,
  },
  title: {
    fontSize: '20px',
    fontWeight: 500,
    lineHeight: '23px',
    paddingLeft: leftOffset,
    paddingBottom: '2px',
  },
};
export default Drawer;
